import { qs, qsa, $on, $delegate } from './utils';
import '../stylesheets/style.scss';

class JR {
  constructor() {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    this.init();
    this.parallax();
    this.scrollTrigger();
    this.initSliderMenu();
    this.megaMenu();
  }

  getMegaState = false;

  init() {
    gsap.to(".jri-banner--wrapper", { 
      duration: 3,
      opacity: 1,
      yPercent: -25,
    });
    
    gsap.to(".jri-scroll", { 
      duration: 1,
      delay: 3,
      opacity: 1
    });

    document.querySelector('.jri-drinks--menu_exit').addEventListener('click', () => {
      const drinksSlider = qs('.jri-drinks--wrapper');
      const drinksMenu = qs('.jri-drinks--menu');
      document.querySelector('.jri-drinks').removeEventListener('mousemove', () => {});

      drinksSlider && drinksSlider.setAttribute('data-active', 'false');
      drinksMenu && drinksMenu.setAttribute('data-active', 'false');
      document.querySelector('.jri-drinks--menu_exit').setAttribute('data-active', 'false');
    });
  }

  dragPointer() {
    const megaMenu = qs('.jri-mega') && qs('.jri-mega');
    const dragPointer = qs('.jri-mega--cursor');
    const dragPointerLinks = qsa('.jri-mega--menu_item-link');
    const menuButton = qs('.jri-navigation--menu_item[data-menu]') && qs('.jri-navigation--menu_item[data-menu]');
    const menuItems = qsa('.jri-navigation--menu_item') && qsa('.jri-navigation--menu_item');
    const menuButtonBar = qsa('.jri-navigation--menu_button-bar') && qsa('.jri-navigation--menu_button-bar');

    if(this.getMegaState === true) {
      document.addEventListener('mousemove', (evt) => {
        dragPointer.setAttribute("style", `top: ${evt.pageY}px; left: ${evt.pageX}px`);
      });
    }
    
    dragPointerLinks.forEach((anchor) => {
      anchor.addEventListener('mouseover', () => {
        document.querySelector('.jri-mega--cursor').setAttribute('data-active', '');
      });
      
      anchor.addEventListener('mouseout', () => {
        document.querySelector('.jri-mega--cursor').removeAttribute('data-active');
      });

      anchor.addEventListener('click', () => {
        document.querySelector('html').setAttribute('data-active', 'false');
        megaMenu.removeAttribute('data-active', 'false');
        menuButton.removeAttribute('data-active');

        menuItems.forEach(item => {
          item.removeAttribute('data-active');
        });
        
        menuButtonBar.forEach(item => {
          item.removeAttribute('data-active');
        });

        setTimeout(() => {
          megaMenu.style.display = 'none';
        }, 100);

        gsap.to(window, {
          scrollTo: `#${anchor.getAttribute('data-target')}`,
          duration: 2
        });

        qs('.jri-logo--image').src = '/images/jriley-line-logo.png';
      })
    });
  }
  
  megaMenu() {
    const menuItems = qsa('.jri-navigation--menu_item') && qsa('.jri-navigation--menu_item');
    const menuButtonBar = qsa('.jri-navigation--menu_button-bar') && qsa('.jri-navigation--menu_button-bar');
    const menuButton = qs('.jri-navigation--menu_item[data-menu]') && qs('.jri-navigation--menu_item[data-menu]');
    const megaMenu = qs('.jri-mega') && qs('.jri-mega');
    const megaMenuLogo = qs('.jri-navigation--menu_item[data-type="logo"]') && qs('.jri-navigation--menu_item[data-type="logo"]');

    menuButton.addEventListener('click', () => {
      if(!menuButton.hasAttribute('data-active')) {
        document.querySelector('html').setAttribute('data-active', 'true');
        megaMenu.style.display = 'block';
        setTimeout(() => {
          megaMenu.setAttribute('data-active', 'true');
          menuItems.forEach(item => {
            item.setAttribute('data-active', '');
          });
         
          menuButtonBar.forEach(bar => {
            bar.setAttribute('data-active', '');
          });
        }, 100);

        this.getMegaState = true;

        if(this.getMegaState === true) {
          qs('.jri-logo--image').src = '/images/jriley-line-logo-black.png';
        }

      }else{
        document.querySelector('html').setAttribute('data-active', 'false');
        megaMenu.setAttribute('data-active', 'false');

        menuItems.forEach(item => {
          item.removeAttribute('data-active');
        });
        
        menuButtonBar.forEach(bar => {
          bar.removeAttribute('data-active');
        });
        
        setTimeout(() => {
          megaMenu.style.display = 'none';
        }, 100);

        this.getMegaState = false;

        if(this.getMegaState === false) {
          qs('.jri-logo--image').src = '/images/jriley-line-logo.png';
        }
      }

      this.dragPointer();
    });
  }

  parallax() {
    const mobile = 320;

    gsap.to(".jri-banner--wrapper", { 
      top: -40,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-banner",
        start: "0%", // the default values
        end: "100%",
        scrub: true,
        // markers: true
      }, 
    });

    // console.log(window.innerWidth === mobile);

    gsap.to(".jri-dining--text", {
      yPercent: (window.innerWidth < 1024) ? -60 : -100,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-dining",
        // start: "80%", // the default values
        // end: "bottom top",
        scrub: true,
        // markers: true
      }, 
    });
    
    gsap.to(".jri-dining--image", {
      yPercent: (window.innerWidth < 1024) ? -10 : -80,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-dining",
        // start: "50%", // the default values
        // end: "125%",
        scrub: true,
        // markers: true
      }, 
    });
    
    gsap.to(".jri-drinks--menu_type.parallax1", {
      yPercent: -50,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-drinks--menu",
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
      }, 
    });
    
    gsap.to(".jri-drinks--menu_type.parallax2", {
      yPercent: -50,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-drinks--menu",
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
      }, 
    });
    
    gsap.to(".jri-about--image", {
      yPercent: -35,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-about",
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
      }, 
    });
    
    gsap.to(".jri-about--details", {
      yPercent: (window.innerWidth < 1024) ? -10 : -60,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-about",
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
      }, 
    });
    
    gsap.to(".strip-outer", {
      yPercent: -25,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-social",
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
      }, 
    });
    
    gsap.to(".jri-special--events", {
      yPercent: -100,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-special",
        // start: "-90%",
        // end: "bottom top",
        scrub: true,
        // markers: true
      }, 
    });
    
    gsap.to(".jri-footer--contact", {
      yPercent: -10,
      ease: "none",
      scrollTrigger: {
        trigger: ".jri-footer",
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
      }, 
    });
  }

  scrollTrigger() {
    const trigger = document.querySelector('.jri-scroll--button');

    trigger.addEventListener('click', () => {
      gsap.to(window, {
        scrollTo: '.jri-dining',
        duration: 2
      });
    });
  }

  initSliderMenu = () => {
    const drinksMenuType = qsa('.jri-drinks--menu_type');
    const drinksSlider = qs('.jri-drinks--wrapper');
    const drinksMenu = qs('.jri-drinks--menu');
    const drinksMenuBackgrounds = qs('.jri-drinks');

    if(drinksMenuType.length > -1) {
      drinksMenuType.forEach((drink) => {
        drink.addEventListener('click', (evt) => {
          if(drinksSlider && drinksMenu) {
            drinksSlider.setAttribute('data-active', 'true');
            drinksMenu.setAttribute('data-active', 'true');

            // const dragCursor = qs('.jri-drag--cursor');
            document.querySelector('.jri-drinks--menu_exit').setAttribute('data-active', 'true');
            // document.querySelector('.jri-drinks').addEventListener('mousemove', (evt) => {
            //   dragCursor.setAttribute("style", `top: ${evt.clientY}px; left: ${evt.pageX}px`);
            // });
          }
        });
        
        drink.addEventListener('mouseenter', (evt) => {
          const selected = evt.target.getAttribute('data-current');
          if (selected === 'whiskey') {
            drinksMenuBackgrounds.style.backgroundPosition = '-100vw 0, 0 0, -100vw 0';
          }else if (selected === 'mixes') {
            drinksMenuBackgrounds.style.backgroundPosition = '-100vw 0, -100vw 0, 0 0';
          }
        });

        drink.addEventListener('mouseleave', () => {
          drinksMenuBackgrounds.style.backgroundPosition = '0 0, 100vw 0, 200vw 0';
        });
      });
    }
  };
}

new JR();